<template>
    <div>
        <el-card style="margin-top: 8px">
            <div slot="header" class="card-header">
                <span>采购与入库</span>
            </div>

            <!-- 批发方使用本系统-->
            <el-card style="margin-top: 20px">
                <!--标题-->
                <el-row class="title" :gutter="100" type="flex">
                    <el-col :span="4" class="content1">
                        <el-button
                            type="text"
                            @click="jumpToPurchaseOrder"
                            class="font1"
                            :disabled="!hasPurchaseOrderPrivilege"
                        >
                            批发方使用本系统
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font2">
                        <span>
                            <el-tooltip
                                class="font2"
                                style="text-decoration: underline"
                                :content="roleNamesForCreatePurchaseOrder"
                                placement="top"
                            >
                                <span>此类角色</span>
                            </el-tooltip>
                            可新建采购单
                        </span>
                        <span style="padding-left: 100px">
                            <el-tooltip
                                class="font2"
                                style="text-decoration: underline"
                                :content="roleNamesForReviewPurchaseOrder"
                                placement="top"
                            >
                                <span>此类角色</span>
                            </el-tooltip>
                            <span>可审核采购单</span>
                        </span>
                        <span style="padding-left: 100px">
                            <el-tooltip
                                class="font2"
                                style="text-decoration: underline"
                                :content="roleNamesForStockPurchaseOrder"
                                placement="top"
                            >
                                <span>此类角色</span>
                            </el-tooltip>
                            <span>可出/入库</span>
                        </span>
                    </el-col>
                </el-row>
                <!--步骤-->
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToPurchaseOrder"
                            :disabled="!hasCreatePurchaseOrderPrivilege"
                            >①新建采购单</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>
                            点击新建-选择配送中心(批发方)-点击选择商品基本信息-选择需要采购的商品-点击确定-填写采购数量(采购价、赠品数量可修改)
                        </span>
                    </el-col>
                </el-row>
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToPurchaseOrder"
                            :disabled="!hasReviewPurchaseOrderPrivilege"
                        >
                            ②审核采购单
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>勾选需要审核的采购单-点击审核-审核成功后可以入库</span>
                    </el-col>
                </el-row>
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToPurchaseInOrder"
                            :disabled="!hasStockPurchaseOrderPrivilege"
                        >
                            ③验货后进行入库
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>勾选需要入库的单据-点击入库-完成后入库的商品可进行销售</span>
                    </el-col>
                </el-row>
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToPurchaseOrder"
                            :disabled="!hasReviewPurchaseOrderPrivilege"
                        >
                            ④入库数量/采购价不正确
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>
                            如审核后发现数量/采购价有误可以进行反审核；在需要反审核的单据上点击查看-审核详情-反审核-确定后可以点击此单据的编辑按钮进行重新填写-之后重新执行审核采购单操作
                        </span>
                    </el-col>
                </el-row>
            </el-card>

            <!--批发方不使用本系统-->
            <el-card style="margin-top: 20px">
                <!--标题-->
                <el-row class="title" :gutter="100" type="flex">
                    <el-col :span="4" class="content1">
                        <el-button
                            type="text"
                            @click="jumpToPurchaseSelfIn"
                            class="font1"
                            :disabled="!hasPurchaseSelfInPrivilege"
                            >批发方不使用本系统</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font2">
                        <span>
                            <el-tooltip
                                class="font2"
                                style="text-decoration: underline"
                                :content="roleNamesForCreatePurchaseSelfIn"
                                placement="top"
                            >
                                <span>此类角色</span>
                            </el-tooltip>
                            <span>可新建采购单</span>
                        </span>
                        <span style="padding-left: 100px">
                            <el-tooltip
                                class="font2"
                                style="text-decoration: underline"
                                :content="roleNamesForReviewPurchaseSelfIn"
                                placement="top"
                            >
                                <span>此类角色</span>
                            </el-tooltip>
                            <span>可审核采购单</span>
                        </span>
                        <span style="padding-left: 100px">
                            <el-tooltip
                                class="font2"
                                style="text-decoration: underline"
                                :content="roleNamesForStockPurchaseSelfIn"
                                placement="top"
                            >
                                <span>此类角色</span>
                            </el-tooltip>
                            <span>可出/入库</span>
                        </span>
                    </el-col>
                </el-row>
                <!--步骤-->
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToPurchaseSelfIn"
                            :disabled="!hasCreatePurchaseSelfInPrivilege"
                            >①新建单方采购入库单</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>
                            点击新建-选择配送中心(批发方)-点击选择商品基本信息-选择需要采购的商品-点击确定-填写采购数量、生产日期(采购价、赠品数量可修改)
                        </span>
                    </el-col>
                </el-row>
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToPurchaseSelfIn"
                            :disabled="!hasReviewPurchaseSelfInPrivilege"
                        >
                            ②审核采购单
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>勾选需要审核的采购单-点击审核-审核成功后可以入库</span>
                    </el-col>
                </el-row>
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToPurchaseSelfIn"
                            :disabled="!hasStockPurchaseSelfInPrivilege"
                        >
                            ③验货后进行入库
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>勾选需要入库的单据-点击入库-完成后入库的商品可进行销售</span>
                    </el-col>
                </el-row>
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToPurchaseSelfIn"
                            :disabled="!hasReviewPurchaseSelfInPrivilege"
                        >
                            ④入库数量/采购价不正确
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>
                            如审核后发现数量/采购价有调整可以进行反审核；在需要反审核的单据上点击查看-审核详情-反审核-确定后可以点击此单据的编辑按钮进行重新填写-之后重新执行审核采购单操作
                        </span>
                    </el-col>
                </el-row>
            </el-card>
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';

const purchaseOrderPrivilegeFlag = 'menu.purchase.order';
const openPurchaseOrderPrivilegeFlag = `${purchaseOrderPrivilegeFlag}.open`;
const createPurchaseOrderPrivilegeFlag = `${purchaseOrderPrivilegeFlag}.create`;
const reviewPurchaseOrderPrivilegeFlag = `${purchaseOrderPrivilegeFlag}.review`;
const purchaseInOrderPrivilegeFlag = `menu.purchase.in`;
const stockPurchaseOrderPrivilegeFlag = `${purchaseInOrderPrivilegeFlag}.stock`;

const purchaseSelfInPrivilegeFlag = 'menu.purchase.selfIn';
const openPurchaseSelfInPrivilegeFlag = `${purchaseSelfInPrivilegeFlag}.open`;
const createPurchaseSelfInPrivilegeFlag = `${purchaseSelfInPrivilegeFlag}.create`;
const reviewPurchaseSelfInPrivilegeFlag = `${purchaseSelfInPrivilegeFlag}.review`;
const stockPurchaseSelfInPrivilegeFlag = `${purchaseSelfInPrivilegeFlag}.stock`;

export default {
    name: 'PurchaseGoodsGuide',
    data() {
        return {
            rolesForCreatePurchaseOrder: [],
            rolesForReviewPurchaseOrder: [],
            rolesForStockPurchaseOrder: [],

            rolesForCreatePurchaseSelfIn: [],
            rolesForReviewPurchaseSelfIn: [],
            rolesForStockPurchaseSelfIn: [],
        };
    },
    created() {
        //查询"能够创建采购订单"的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(createPurchaseOrderPrivilegeFlag).then((rst) => {
            this.rolesForCreatePurchaseOrder = rst;
        });
        //查询"能够审核采购订单"的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(reviewPurchaseOrderPrivilegeFlag).then((rst) => {
            this.rolesForReviewPurchaseOrder = rst;
        });
        //查询"能够入库采购订单"的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(stockPurchaseOrderPrivilegeFlag).then((rst) => {
            this.rolesForStockPurchaseOrder = rst;
        });

        //查询"能够创建单方采购入库单据"的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(createPurchaseSelfInPrivilegeFlag).then((rst) => {
            this.rolesForCreatePurchaseSelfIn = rst;
        });
        //查询"能够审核单方采购入库单据"的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(reviewPurchaseSelfInPrivilegeFlag).then((rst) => {
            this.rolesForReviewPurchaseSelfIn = rst;
        });
        //查询"能够入库单方采购入库单据"的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(stockPurchaseSelfInPrivilegeFlag).then((rst) => {
            this.rolesForStockPurchaseSelfIn = rst;
        });
    },
    computed: {
        roleNamesForCreatePurchaseOrder() {
            return this.rolesForCreatePurchaseOrder.map((e) => e.name).join('，');
        },
        roleNamesForReviewPurchaseOrder() {
            return this.rolesForReviewPurchaseOrder.map((e) => e.name).join('，');
        },
        roleNamesForStockPurchaseOrder() {
            return this.rolesForStockPurchaseOrder.map((e) => e.name).join('，');
        },

        roleNamesForCreatePurchaseSelfIn() {
            return this.rolesForCreatePurchaseSelfIn.map((e) => e.name).join('，');
        },
        roleNamesForReviewPurchaseSelfIn() {
            return this.rolesForReviewPurchaseSelfIn.map((e) => e.name).join('，');
        },
        roleNamesForStockPurchaseSelfIn() {
            return this.rolesForStockPurchaseSelfIn.map((e) => e.name).join('，');
        },
    },

    methods: {
        jumpToPurchaseOrder() {
            Util.nameJump(this, purchaseOrderPrivilegeFlag);
        },
        jumpToPurchaseInOrder() {
            Util.nameJump(this, purchaseInOrderPrivilegeFlag);
        },
        jumpToPurchaseSelfIn() {
            Util.nameJump(this, purchaseSelfInPrivilegeFlag);
        },

        hasPurchaseOrderPrivilege() {
            return this.hasPrivilege(openPurchaseOrderPrivilegeFlag);
        },
        hasCreatePurchaseOrderPrivilege() {
            return this.hasPrivilege(createPurchaseOrderPrivilegeFlag);
        },
        hasReviewPurchaseOrderPrivilege() {
            return this.hasPrivilege(reviewPurchaseOrderPrivilegeFlag);
        },
        hasStockPurchaseOrderPrivilege() {
            return this.hasPrivilege(stockPurchaseOrderPrivilegeFlag);
        },

        hasPurchaseSelfInPrivilege() {
            return this.hasPrivilege(openPurchaseSelfInPrivilegeFlag);
        },
        hasCreatePurchaseSelfInPrivilege() {
            return this.hasPrivilege(createPurchaseSelfInPrivilegeFlag);
        },
        hasReviewPurchaseSelfInPrivilege() {
            return this.hasPrivilege(reviewPurchaseSelfInPrivilegeFlag);
        },
        hasStockPurchaseSelfInPrivilege() {
            return this.hasPrivilege(stockPurchaseSelfInPrivilegeFlag);
        },
    },
};
</script>

<style scoped>
.font1 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: bold;
    text-decoration: underline;
    color: #36bf5d;
    line-height: 28px;
}

.font2 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #606266;
    line-height: 20px;
}

.font3 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #9ca1ab;
    line-height: 20px;
}
.title {
    background: #d6ffe2;
    border-radius: 4px 4px 0 0;
}

.content1 {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content2 {
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.el-row {
    height: 70px;
}

.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
    color: #c0c4cc;
    cursor: not-allowed;
}

/deep/ .el-card__body {
    padding-top: 0;
}

.card-header {
    font-size: 20px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #212121;
    line-height: 28px;
}
</style>
